import {
  BOOL_FIELD_VALUE,
  CODE_TYPE_FIELD_VALUE,
  LABEL_STATISTICS_FIELDS,
  STATISTICS_FIELDS,
} from '../constants/statistic';

const getPrepareSchemes = schemas => ({
  ...schemas, DifferentClosingSchemes: 'Разные схемы закрывающих на юридических лицах',
});

const getFieldValue = (value, fieldName, schemas) => {
  switch (fieldName) {
    case STATISTICS_FIELDS.AEROFLOT_CODE:
    case STATISTICS_FIELDS.S_7_CODE: {
      return CODE_TYPE_FIELD_VALUE[value];
    }

    case STATISTICS_FIELDS.CUSTOM_CLOSING_SCHEME: {
      return schemas.hasOwnProperty(value) ? schemas[value] : BOOL_FIELD_VALUE.NO;
    }

    case STATISTICS_FIELDS.PERCENTAGE_USED: {
      return `${value.toFixed(2)}%`;
    }

    case STATISTICS_FIELDS.BUY_HOTELS:
    case STATISTICS_FIELDS.PACKAGES_1_C:
    case STATISTICS_FIELDS.CHOSE_PLACES:
    case STATISTICS_FIELDS.ONE_TIME_CERTIFICATE:
    case STATISTICS_FIELDS.SUBSCRIBE_TO_CERTIFICATE:
    case STATISTICS_FIELDS.MOBILE:
    case STATISTICS_FIELDS.VOIDING:
    case STATISTICS_FIELDS.INTEGRATION_API:
    case STATISTICS_FIELDS.REQUESTS_1C:
    case STATISTICS_FIELDS.INTEGRATION_1C:
    case STATISTICS_FIELDS.TAXI:
    case STATISTICS_FIELDS.EXPENSE_REPORT:
    case STATISTICS_FIELDS.TRAVEL_POLICIES:
    case STATISTICS_FIELDS.APPROVAL_SCHEMES:
    case STATISTICS_FIELDS.USER_ANALYTICS_OR_COST_CENTERS:
    case STATISTICS_FIELDS.MOBILE_AUTH:
    case STATISTICS_FIELDS.MOBILE_BOOKING: {
      return value ? BOOL_FIELD_VALUE.YES : BOOL_FIELD_VALUE.NO;
    }

    default:
      return value;
  }
};

const prepareStatisticFunctional = (periods, schemas = {}) => {
  if (!periods.length) {
    return [];
  }

  const previousMonthStatistics = periods[0].Statistics;
  const currentMonthStatistics = periods[1].Statistics;

  const prepareSchemes = getPrepareSchemes(schemas);

  return Object.values(STATISTICS_FIELDS).reduce((acc, key) => {
    if (previousMonthStatistics.hasOwnProperty(key)
      && currentMonthStatistics.hasOwnProperty(key)) {
      const obj = {
        key,
        label: LABEL_STATISTICS_FIELDS[key],
        currentMonthValue: getFieldValue(currentMonthStatistics[key], key, prepareSchemes),
        previousMonthValue: getFieldValue(previousMonthStatistics[key], key, prepareSchemes),
      };

      return [...acc, obj];
    }

    return acc;
  }, []);
};

export { prepareStatisticFunctional, getPrepareSchemes };
