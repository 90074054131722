export const VALID_SYMBLOL = {
  DOT: '.',
  COMMA: ',',
  ZERO: '0',
};

const REG = /^(0|[1-9]\d*)(\.\d{0,2})?$/;

const priceValidation = (value) => {
  const validFirstSybmol = value
    ? value[0] !== VALID_SYMBLOL.DOT && value[0] !== VALID_SYMBLOL.ZERO && value[0] !== VALID_SYMBLOL.COMMA
    : value;

  const zeroDot = val => (val[0] === VALID_SYMBLOL.ZERO && val[1] === VALID_SYMBLOL.DOT);
  const zeroComma = val => (val[0] === VALID_SYMBLOL.ZERO && val[1] === VALID_SYMBLOL.COMMA);

  const fiftyCent = value ? zeroDot(value) || zeroComma(value) : !value;

  return !!(REG.test(value) && (fiftyCent || validFirstSybmol));
};

export {
  priceValidation,
};
