import moment from 'moment';

import lodashReplaces from './lodashReplaces';

import {
  isMoment,
  momentObject,
  isValidMomentObject,
  dateLessThanOtherDate,
  getTimeFromSpaceFormat,
  getTimeFromTimezoneFormat,
} from '../../bi/utils/formatDate';
import { CODE, PASSPORT } from '../../bi/constants/employee';

import { SERVICETYPE } from '../../bi/constants/serviceType';
import {
  FIELDS,
  PRICE_DETAILS,
  TRAIN_PRICING,
  TRIPSLABELS,
  KEYS_NOT_NUMBER,
} from '../../bi/constants/trips';
import { onlyNumbers, point } from '../../bi/constants/regExp';
import { FULLTIME } from '../../constants/time';
import { TYPERATRATES } from '../constants/account';
import { KALININGRAD_CITY_NAME } from '../../bi/constants/train';

const pickValue = value => value !== '0' && value !== 0;

const actualIntegrationVersion = (integrationVersions, itemVersionId) => {
  const filteredVersions = integrationVersions.length ? integrationVersions
    .filter(({ TripItemVersionId }) => TripItemVersionId === itemVersionId) : [];

  return filteredVersions.length
    ? filteredVersions.sort((first, second) => second.Id - first.Id)[0]
    : null;
};

const isUploadedIntegrationVersion = (orderItems, integrationVersions) => integrationVersions.length > 0 && orderItems && orderItems.length > 0 && (
  orderItems.some(({ ActualVersion: { Id } }) =>
    integrationVersions.some(({ TripItemVersionId, Uploaded }) =>
      TripItemVersionId === Id && Uploaded))
);

const findDepartments = (employee, CompanyId) => {
  if (!employee.Id) return [];

  const employeeCompany = employee.Companies.find(company => company.CompanyId === +CompanyId);

  if (!employeeCompany || !Array.isArray(employeeCompany.Departments) || !employeeCompany.Departments.length) return [];

  const { Departments } = employeeCompany;

  return Departments.map(({ Id, Name }) => ({
    id: Id,
    name: Name,
  }));
};

const preparedDepartments = (employees, currentEmployeeId, CompanyId) => {
  if (!employees.length) {
    return [];
  }
  const currentEmployee = employees.find(({ Id }) => currentEmployeeId === Id);
  if (!currentEmployee) {
    return [];
  }
  return findDepartments(currentEmployee, CompanyId);
};


const pickProviderName = value => value !== '';

const pickProviderOfficeId = value => value !== '' && value !== null;

const normalizeNumber = (value) => {
  const withoutSpace = String(value).replace(/[^0-9^,^.]/g, '');
  return Number(withoutSpace.replace(',', '.'));
};

const getSumPrices = (JsonData) => {
  const makePrice = () => {
    const firstTerm = lodashReplaces.getValueInObjByPath(JsonData, TRAIN_PRICING.TARIFF_PRICE);
    const secondTerm = lodashReplaces.getValueInObjByPath(JsonData, TRAIN_PRICING.FARE);

    return normalizeNumber(firstTerm) + normalizeNumber(secondTerm);
  };

  const sum = makePrice();

  return [
    { value: sum, path: `${FIELDS.JSON_DATA}.${TRAIN_PRICING.COST}` },
    { value: sum, path: `${FIELDS.JSON_DATA}.${PRICE_DETAILS.TARIFF_PRICE}` },
  ];
};

const normalizeDate = (date) => {
  if (moment.isMoment(date)) {
    return date.format(FULLTIME);
  }
  return moment(date).format(FULLTIME);
};

const isInputInvalid = (value, isSymbolInvalid, limit) => isSymbolInvalid || String(value).length >= limit + 1;

const changeCommaToThePoint = value => value.replace(point, '.');

const requiredTime = (value) => {
  if (typeof value === 'string') {
    const maxHours = 24;
    const maxMinutes = 60;
    const numbers = value.split(':');
    const isNumbers = onlyNumbers.test(numbers.join(''));
    const isValid = value.includes(':') && value.length === 5 && isNumbers && numbers[0] <= maxHours && numbers[1] <= maxMinutes;

    return isValidMomentObject(value) && isValid;
  }

  return isMoment(value) && isValidMomentObject(value);
};

const requireValue = value => value !== '' && Number(value) !== 0;

const makeTrainJsonData = oldJsonData => ({
  EmployeeIds: [oldJsonData.EmployeeId],
  ServiceType: 'Train',
  TicketId: oldJsonData.TicketId,
  PassportId: oldJsonData.PassportId,
  ProviderComission: oldJsonData.ProviderComission,
  Route: {
    StationDepart: oldJsonData.StationDepart,
    StationDepartCode: oldJsonData.StationDepartCode,
    StationArrive: oldJsonData.StationArrive,
    StationArriveCode: oldJsonData.StationArriveCode,
    DateDepartureLocal: oldJsonData.DateDepartureLocal,
    DateArriveLocal: oldJsonData.DateArrivalLocal,
    DateDeparture: oldJsonData.DateDeparture,
    DateArrive: oldJsonData.DateArrive,
    TrainNumber: oldJsonData.TrainNumber,
    CarNumber: oldJsonData.CarNumber,
    PlaceNumber: oldJsonData.PlaceNumber,
    CarTypeDescription: oldJsonData.CarTypeDescription,
    CarType: oldJsonData.CarType,
    ClassService: oldJsonData.ClassService,
    SeatType: oldJsonData.SeatType,
  },

  TrainPricing: {
    TariffPrice: oldJsonData.TrainPricing.TariffPrice,
    Fare: oldJsonData.TrainPricing.Fare,
    Cost: oldJsonData.TrainPricing.Cost,
    ServiceTva: oldJsonData.TrainPricing.ServiceTva,
    ServiceTvaRate: 20,
  },

  PriceDetails: {
    Commission: oldJsonData.PriceDetails.Commission + oldJsonData.PriceDetails.Fee,
    TariffPrice: oldJsonData.TrainPricing.TariffPrice,
  },

  CarrierDetails: {
    Carrier: oldJsonData.Carrier,
    CarrierFull: oldJsonData.CarrierFull,
    CarrierInn: oldJsonData.CarrierInn,
  },

  guid: oldJsonData.TicketFileId,
});

const makeTrainDatesToMoment = (Route, fromTime = true) => {
  const timeDeparture = fromTime ? momentObject(Route.TimeDeparture) : momentObject(Route.DateDeparture);
  const timeDepartureLocal = fromTime ? momentObject(Route.TimeDepartureLocal) : momentObject(Route.DateDepartureLocal);
  const timeArrive = fromTime ? momentObject(Route.TimeArrive) : momentObject(Route.DateArrive);
  const timeArriveLocal = fromTime ? momentObject(Route.TimeArriveLocal) : momentObject(Route.DateArrivalLocal);

  return {
    ...Route,
    DateDepartureLocal: momentObject(Route.DateDepartureLocal),
    DateArriveLocal: momentObject(Route.DateArrivalLocal),
    DateDeparture: momentObject(Route.DateDeparture),
    DateArrive: momentObject(Route.DateArrive),
    TimeDeparture: timeDeparture,
    TimeDepartureLocal: timeDepartureLocal,
    TimeArrive: timeArrive,
    TimeArriveLocal: timeArriveLocal,
  };
};

const makeCopyTrainJsonData = (JsonData) => {
  const Route = makeTrainDatesToMoment(JsonData.Route);
  Route.PlaceNumber = '';

  return {
    ...JsonData,
    Route,
    TicketId: '',
    guid: '',
  };
};

const citiesForAir = Routes =>
  Routes.map(route => route.Segments.map(segment => `${segment.DepartureCity} - ${segment.ArrivalCity}`)).join('; ');

const getNumberVoucher = TicketsExtended => TicketsExtended.map(num => `${num.Num}`).join(', ');

const infoEmployee = ({ VoucherTravellers = [] }) => VoucherTravellers.map(name =>
  `${name.Surname} ${name.Name.charAt(0)}.${name.Patronymic ? `${name.Patronymic.charAt(0)}.` : ''}`);

const routeTaxi = (route) => {
  if (!route) {
    return null;
  }

  const { Country: dCountry, City: dCity, Address: { Street: dStreet, Number: dNumber, Extra: dExtra } } = route;

  return `${dCountry}, ${dCity}${dStreet ? `, ${dStreet}` : ''}${dNumber ? `, ${dNumber}` : ''}${dExtra ? `, ${dExtra}` : ''}`;
};

const convertTransferMetadata = (data) => {
  const {
    StartPlace,
    EndPlace,
    IncludeTablet,
    CancellationDate,
    DateArrival,
    DateDeparture,
    ArrivalNumber,
    PriceDetails,
    CarModel,
    GeneralOrderData: { OrderId },
    Note,
    TableSignature,
    CarData: { Capacity, Title },
    CarriageNumber,
    PriceDetails: { Base, Commission, Fee },
  } = data;

  const jsonData = {
    StartPlace,
    EndPlace,
    IncludeTablet,
    CancellationDate,
    CancellationTime: CancellationDate ? getTimeFromTimezoneFormat(CancellationDate) : '',
    CheckinTime: DateArrival ? getTimeFromSpaceFormat(DateArrival) : '',
    CheckoutTime: DateDeparture ? getTimeFromSpaceFormat(DateDeparture) : '',
    CheckinDate: DateArrival,
    CheckoutDate: DateDeparture,
    ArrivalNumber,
    PriceDetails,
    CarModel,
    ProviderOrderId: OrderId,
    Note,
    TableSignature,
    Capacity,
    CarClass: Title,
    CarriageNumber,
    ProviderName: '',
    Viewers: data.Viewers || [''],
  };

  return {
    OrderTripItemId: 0,
    Employees: [],
    ServiceType: SERVICETYPE.TRANSFER,
    PriceDetails: {
      Base,
      Fee,
      commission: Commission,
    },
    JsonData: jsonData,
  };
};

const validateDateAndTime = (field, value, isEditing, jsonData, validation) => {
  const { CheckinDate, CheckoutDate } = jsonData;

  let result;
  let resultCheckin;
  let resultCheckout;
  let resultCheckoutLess;

  const checkin = momentObject(CheckinDate);
  const checkout = momentObject(CheckoutDate);

  if (dateLessThanOtherDate(checkin, checkout) < 0 &&
    (field === FIELDS.CHECKOUT_DATE || field === FIELDS.CHECKOUTTIME)) {
    resultCheckin = validation(!isEditing ? FIELDS.CHECKIN_DATE : FIELDS.CHECKIN_DATE_EDIT, jsonData);
    resultCheckoutLess = validation(FIELDS.CHECKOUTDATELESS, jsonData);
  } else if (checkin.isValid() || checkout.isValid()) {
    resultCheckin = validation(!isEditing ? FIELDS.CHECKIN_DATE : FIELDS.CHECKIN_DATE_EDIT, jsonData);
    resultCheckout = validation(!isEditing ? FIELDS.CHECKOUT_DATE : FIELDS.CHECKOUT_DATE_EDIT, jsonData);
    resultCheckoutLess = validation(FIELDS.CHECKOUTDATELESS, jsonData);
  } else {
    result = validation(field, value);
  }

  return { result, resultCheckin, resultCheckout, resultCheckoutLess };
};

const setDateInMomentPenalties = array => array.map(penalty => ({ ...penalty, From: momentObject(penalty.From) }));

const getRefundText = (price = 0, refundText = '', cancellationText = '') =>
  `${price >= 0 ? refundText : cancellationText} ${Math.abs(price)} р.`;

const getTotalPrice = ({ Base, commission, Fee = 0 }) =>
  Number(Base) + Number(commission) + Number(Fee);

const getProviderOldName = (providersList = [], providerName = '') => {
  let providerOldName = providersList.find(({ label }) => label === providerName);

  if (!providerOldName && providerName !== '') {
    providerOldName = { id: 0, type: TYPERATRATES.OPTIONAL, value: null, label: providerName };
  }

  return providerOldName;
};

const prepareCancellationPenalties = json => json.Hotel.CancellationPenalties ?
  json.Hotel.CancellationPenalties.map(item => ({
    ...item,
    From: item.From ? momentObject(item.From) : '',
  })) : null;

const generateEmployeePlaceholder = number => `Сотрудник ${number + 1}`;

const isIntNumberOrDash = (value) => {
  const regexIntNumber = /^[0-9\b]+$/;

  return regexIntNumber.test(value) || value === '-';
};

const isEmptyPenalties = penalties => penalties && penalties.length ?
  penalties.find(({ ProviderPrice, From }) => !ProviderPrice || !From) :
  false;

const prepareDatesInsurance = (selectedItems) => {
  const isAviaFirstItem = selectedItems[0].ServiceType === SERVICETYPE.AIR;
  const datesAir = isAviaFirstItem ? JSON.parse(selectedItems[0].Data).AirlineRoutesDates : null;

  const checkinFirst = isAviaFirstItem ?
    datesAir[0].CheckinDate :
    JSON.parse(selectedItems[0].Data).CheckinDate;
  const checkinDate = selectedItems.reduce((pre, service) => {
    const checkin = service.ServiceType === SERVICETYPE.AIR ?
      JSON.parse(service.Data).AirlineRoutesDates[0].CheckinDate :
      JSON.parse(service.Data).CheckinDate;

    if (momentObject(checkin) < momentObject(pre)) {
      return checkin;
    }

    return pre;
  }, checkinFirst);

  const checkoutFirst = isAviaFirstItem ?
    datesAir[datesAir.length - 1].CheckoutDate :
    JSON.parse(selectedItems[0].Data).CheckoutDate;
  const checkoutDate = selectedItems.reduce((pre, service) => {
    let checkout = '';

    if (service.ServiceType === SERVICETYPE.AIR) {
      const checkoutAir = JSON.parse(service.Data).AirlineRoutesDates;

      checkout = checkoutAir[checkoutAir.length - 1].CheckoutDate;
    } else {
      checkout = JSON.parse(service.Data).CheckoutDate || JSON.parse(service.Data).CheckinDate;
    }

    if (momentObject(checkout) > momentObject(pre)) {
      return checkout;
    }

    return pre;
  }, checkoutFirst);

  return { checkinDate, checkoutDate };
};

const calculationOfVAT = (value) => {
  const vat = (Number(value) / 120) * 20;
  const roundedVAT = Math.round(vat * 100) / 100;

  return roundedVAT;
};

const defaultDataCancel = (item) => {
  const { OrderTripItemId, ServiceType, ProviderName, UserAnalytics } = item;

  return {
    OrderTripItemId,
    Status: 2,
    Description: TRIPSLABELS.PENALTY_FOR_CANCELLATION,
    ServiceType,
    Penalties: [{
      Penalties: 0,
      Tax: 0,
      PenaltiesCurrency: 0,
      Description: TRIPSLABELS.PENALTY_FOR_CANCELLATION,
    }],
    ReturnFunds: [],
    Surcharges: [],
    ProviderName,
    UserAnalytics,
  };
};

const preventKeyNotNumber = (e) => {
  if (e.key === KEYS_NOT_NUMBER.PLUS
  || e.key === KEYS_NOT_NUMBER.MINUS
  || e.key === KEYS_NOT_NUMBER.E) {
    return e.preventDefault();
  }
};

const checkIsStationEqualCity = (station, cityName) => (
  station && station.toLowerCase().includes(cityName.toLowerCase())
);

const checkIsKaliningradTicket = (stationDepart, stationArrive) => (
  checkIsStationEqualCity(stationDepart, KALININGRAD_CITY_NAME)
    || checkIsStationEqualCity(stationArrive, KALININGRAD_CITY_NAME)
);

const checkIsRussianCitizen = Code => Code === CODE.RU;

const checkIsBelarusianCitizen = Code => Code === CODE.BY;

const checkIsForeignPassportIdExists = (Documents, PassportId) =>
  Documents
  && PassportId
  && Documents.some(
    document =>
      document.Type === PASSPORT.FOREIGNPASSPORT
      && document.Id === Number(PassportId)
  );
const updateSelectedTrips = (trips, selectedTripsId) => trips.reduce((acc, { Id }) => {
  if (selectedTripsId.includes(Id)) {
    acc.push(Id);
  }

  return acc;
}, []);

export {
  pickValue,
  actualIntegrationVersion,
  isUploadedIntegrationVersion,
  preparedDepartments,
  findDepartments,
  pickProviderName,
  pickProviderOfficeId,
  normalizeNumber,
  makeTrainJsonData,
  normalizeDate,
  isInputInvalid,
  changeCommaToThePoint,
  requiredTime,
  citiesForAir,
  makeCopyTrainJsonData,
  makeTrainDatesToMoment,
  requireValue,
  getNumberVoucher,
  infoEmployee,
  routeTaxi,
  convertTransferMetadata,
  validateDateAndTime,
  setDateInMomentPenalties,
  getRefundText,
  getTotalPrice,
  getSumPrices,
  getProviderOldName,
  prepareCancellationPenalties,
  generateEmployeePlaceholder,
  isIntNumberOrDash,
  isEmptyPenalties,
  prepareDatesInsurance,
  calculationOfVAT,
  defaultDataCancel,
  preventKeyNotNumber,
  checkIsKaliningradTicket,
  checkIsRussianCitizen,
  checkIsBelarusianCitizen,
  checkIsForeignPassportIdExists,
  updateSelectedTrips,
};
