import moment from 'moment';

export default function parseUnix(value, locale = 'ru') {
  let time = value;

  if (typeof value === 'number' && value.toString().length === 10) {
    time *= 1000;
  }

  const origin = moment.utc(time);
  origin.locale(locale);

  return origin;
}
