import { FIELDS, FIELDS_TYPES, LABELS_FIELDS_FORM } from '../constants/regions';

const regionFields = [
  { field: FIELDS.NAME_RU, label: LABELS_FIELDS_FORM.NAME_RU, type: FIELDS_TYPES.INPUT },
  { field: FIELDS.COUNTRY_NAME_RU, label: LABELS_FIELDS_FORM.COUNTRY_NAME_RU, type: FIELDS_TYPES.SELECT },
  { field: FIELDS.NAME_EN, label: LABELS_FIELDS_FORM.NAME_EN, type: FIELDS_TYPES.INPUT },
  { field: FIELDS.COUNTRY_NAME_EN, label: LABELS_FIELDS_FORM.COUNTRY_NAME_EN, type: FIELDS_TYPES.INPUT },
  { field: FIELDS.RADIUS, label: LABELS_FIELDS_FORM.RADIUS, type: FIELDS_TYPES.INPUT },
  { field: FIELDS.COUNTRY_CODE, label: LABELS_FIELDS_FORM.COUNTRY_CODE, type: FIELDS_TYPES.INPUT },
  { field: FIELDS.LATITUDE, label: LABELS_FIELDS_FORM.LATITUDE, type: FIELDS_TYPES.INPUT },
  { field: FIELDS.LONGITUDE, label: LABELS_FIELDS_FORM.LONGITUDE, type: FIELDS_TYPES.INPUT },
];

const prepareRegionFields = details => regionFields.reduce((acc, el) =>
  ({ ...acc, [el.field]: { ...el, value: details[el.field] } }), {});

const normalizeRegionFields = (fields) => {
  const keys = Object.keys(fields).filter(key => key !== 'Id');

  return keys.reduce((acc, key) => {
    const { value } = fields[key];
    return { ...acc, [key]: value };
  }, {});
};

const makeDefaultForm = () => {
  const defaultFormData = {
    CountryCode: '',
    Latitude: '',
    Longitude: '',
    Radius: '',
    NameRu: '',
    CountryNameRu: '',
    NameEn: '',
    CountryNameEn: '',
  };

  return prepareRegionFields(defaultFormData);
};

export {
  prepareRegionFields,
  normalizeRegionFields,
  makeDefaultForm,
};
