import { formatDate } from '../../utils/date';

import { DAY_OF_MONTH_WITH_YEAR_AND_TIME, FULLPATTERN } from '../../constants/time';
import { SERVICETYPE } from '../constants/serviceType';

const prepareDate = date => formatDate(date, DAY_OF_MONTH_WITH_YEAR_AND_TIME);
const prepareDateFullPattern = date => formatDate(date, FULLPATTERN);

const getLabelCheckboxItem = (Data, ServiceType) => {
  const data = JSON.parse(Data);

  switch (ServiceType) {
    case SERVICETYPE.HOTEL: {
      const { Name, City, CheckinDate, CheckoutDate } = data;

      return `${Name}, г.${City}, ${prepareDateFullPattern(CheckinDate)} - ${prepareDateFullPattern(CheckoutDate)}`;
    }

    case SERVICETYPE.AIR: {
      const { Direction, AirlineRoutesDates, Number: AirNumber } = data;
      const checkinDate = AirlineRoutesDates[0].CheckinDate;
      const checkoutDate = AirlineRoutesDates[AirlineRoutesDates.length - 1].CheckoutDate;

      return `${Direction}, ${prepareDate(checkinDate)} - ${prepareDate(checkoutDate)}, ${AirNumber}`;
    }

    case SERVICETYPE.TRANSFER: {
      const { Direction, CheckinDate } = data;

      return `${Direction}, ${prepareDate(CheckinDate)}`;
    }

    case SERVICETYPE.VIP_HALL: {
      const { Direction, CheckinDate } = data;

      return `${Direction}, ${prepareDate(CheckinDate)}`;
    }

    case SERVICETYPE.TAXI: {
      const { CheckinDate, CheckoutDate } = data;

      return `${prepareDate(CheckinDate)} - ${prepareDate(CheckoutDate)}`;
    }

    case SERVICETYPE.TRAIN: {
      const { Direction, CheckinDate, CheckoutDate } = data;

      return `${Direction}, ${prepareDate(CheckinDate)} - ${prepareDate(CheckoutDate)}`;
    }

    case SERVICETYPE.CUSTOM: {
      const { Name, CheckinDate } = data;

      return `${Name}, ${prepareDate(CheckinDate)}`;
    }

    case SERVICETYPE.BUS: {
      const { Direction, CheckinDate } = data;
      const direction = Direction.replace('Автобусный билет', '');

      return `${direction}, ${prepareDate(CheckinDate)}`;
    }

    default: return '';
  }
};

export { getLabelCheckboxItem };
