import {
  BUYTRIPSPERSONALRIGHT,
  BUYTRIPSACCOUNTRIGHT,
  FINANCEDOCUMENTSRIGHT,
  APPROVETRIPRIGHT,
  EDITEMPLOYEERIGHT,
  VIEWTRIPSRIGHT,
  VIEW_EXPENSE_REPORTS_RIGHT,
  EDITACCESSRIGHT,
  NOTIFICATIONRIGHT,
  DOCUMENTS,
  RIGHTSTEXT,
  FIELDRIGHTS,
  SHORTFIELDRIGHTS,
  APPROVE_EXPENSE_REPORTS,
  CREATE_EXPENCE_REPORTS,
  EDIT_MICE_RIGTHS,
} from '../constants/rights';

export const preparedRightsToFrontend = ({
  BuyTripPersonal,
  BuyTripAccount,
  BuyInsurance,
  Approve,
  Finance,
  EditEmployee,
  ViewTrips,
  ViewExpenseReports,
  EditRights,
  EditTravelPolicies,
  Notifications,
  SendDocuments,
  ApproveExpenseReports,
  CreateExpenseReports,
  ViewMiceEvents,
}) => {
  const buyTripAccount = BuyTripAccount !== BUYTRIPSACCOUNTRIGHT.Unavailable;
  const buyTripPersonal = BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;
  const buyTravelPolicy = BuyTripAccount === BUYTRIPSACCOUNTRIGHT.TravelPolicy || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.TravelPolicy;
  const buyApprovalScheme = BuyTripAccount === BUYTRIPSACCOUNTRIGHT.ApprovalScheme || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.ApprovalScheme;
  const approveExpenseReports = ApproveExpenseReports !== APPROVE_EXPENSE_REPORTS.Unavailable;
  const viewExpenseReports = ViewExpenseReports !== VIEW_EXPENSE_REPORTS_RIGHT.Undefined;
  const createExpenseReports = CreateExpenseReports !== CREATE_EXPENCE_REPORTS.Undefined;

  const showEditTravelPolicy = buyTripAccount || !buyTripPersonal || !buyTravelPolicy;
  const accountVouchers = NOTIFICATIONRIGHT.AccountVouchers;
  const accountInvoices = NOTIFICATIONRIGHT.AccountInvoices;
  const canByVouchersInvoices = Notifications === NOTIFICATIONRIGHT.PersonalVouchersAndInvoices || Notifications === NOTIFICATIONRIGHT.AccountVouchersAndInvoices;

  const editEmployee = EditEmployee === EDITEMPLOYEERIGHT.All;
  const disableEditRights = !editEmployee;
  const editRights = editEmployee && EditRights === EDITACCESSRIGHT.Available;

  let canGetVouchers = false;
  let canGetInvoices = false;

  if (Notifications !== NOTIFICATIONRIGHT.Unavailable) {
    canGetVouchers = canByVouchersInvoices || Notifications === NOTIFICATIONRIGHT.PersonalVouchers || Notifications === accountVouchers;
    canGetInvoices = canByVouchersInvoices || Notifications === NOTIFICATIONRIGHT.PersonalInvoices || Notifications === accountInvoices;
  }

  const documents = Notifications === accountInvoices ||
    Notifications === accountVouchers ||
    Notifications === NOTIFICATIONRIGHT.AccountVouchersAndInvoices ? DOCUMENTS.AccountTrips : DOCUMENTS.PersonalTrips;

  const buyUnlimited = BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unlimited ||
    BuyTripPersonal === BUYTRIPSPERSONALRIGHT.Unlimited ||
    BuyTripPersonal === BUYTRIPSPERSONALRIGHT.Limited; // дабы увести всех пользователей от старых лимитов

  return {
    createExpenseReports,
    approveExpenseReports,
    buyTripPersonal,
    buyTripAccount,
    buyUnlimited,
    buyApprovalScheme,
    disableEditRights,
    editEmployee,
    buyTravelPolicy,
    showEditTravelPolicy,
    canGetVouchers,
    canGetInvoices,
    documents,
    editRights,
    disableBuyTripPersonal: buyTripAccount,
    approve: Approve === APPROVETRIPRIGHT.Available,
    finance: Finance === FINANCEDOCUMENTSRIGHT.Available,
    showSecondStep: buyTripPersonal || buyTripAccount,
    buyAfterApprove: BuyTripAccount === BUYTRIPSACCOUNTRIGHT.OnlyAfterApprove || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.OnlyAfterApprove,
    editSelf: EditEmployee !== EDITEMPLOYEERIGHT.Undefined,
    viewTrips: ViewTrips === VIEWTRIPSRIGHT.All,
    viewSelfTrips: ViewTrips === VIEWTRIPSRIGHT.All || ViewTrips === VIEWTRIPSRIGHT.Personal,
    viewExpenseReports,
    editTravelPolicies: showEditTravelPolicy ? EditTravelPolicies : false,
    sendDocuments: SendDocuments,
    showSelectDocuments: buyTripAccount || buyTripPersonal,
    createERAfterApprove: CreateExpenseReports === CREATE_EXPENCE_REPORTS.OnlyAfterApprove,
    createERUnlimited: CreateExpenseReports === CREATE_EXPENCE_REPORTS.Unlimited,
    createERApprovalScheme: CreateExpenseReports === CREATE_EXPENCE_REPORTS.ApprovalScheme,
    buyInsurance: BuyInsurance,
    viewMiceEvents: ViewMiceEvents === EDIT_MICE_RIGTHS.All,
  };
};

const checkDocuments = (canGetVouchers, canGetInvoices) => {
  let mainText = '';

  if (canGetVouchers && !canGetInvoices) {
    mainText = RIGHTSTEXT.CANGETVOUCHERS;
  } else if (!canGetVouchers && canGetInvoices) {
    mainText = RIGHTSTEXT.CANGETINVOICES;
  } else if (canGetVouchers) {
    mainText = RIGHTSTEXT.CANGETVOUCHERSANDINVOICES;
  }

  return mainText;
};

const selectAdditionalText = (checkBuyAA, checkBuyUnlimited, checkBuyTripAccount) => {
  const selectCreateER = (text, anotherText) => (
    checkBuyTripAccount ? RIGHTSTEXT.FOR_EMPLOYEE + text : RIGHTSTEXT.FOR_MYSELF + (anotherText || text)
  );

  if (checkBuyAA) {
    return selectCreateER(RIGHTSTEXT.BUYAFTERAPPROVE);
  }

  if (checkBuyUnlimited) {
    return selectCreateER(RIGHTSTEXT.BUYUNLIMITED);
  }

  return selectCreateER(RIGHTSTEXT.BUY_APPROVAL_SCHEME_ONLY, RIGHTSTEXT.BUY_APPROVAL_SCHEME);
};

export const convertAvailableRightsToText = ({
  buyTripPersonal,
  buyTripAccount,
  buyUnlimited,
  buyAfterApprove,
  buyApprovalScheme,
  buyTravelPolicy,
  // buyInsurance,
  approve,
  finance,
  editSelf,
  editEmployee,
  editTravelPolicies,
  viewTrips,
  viewSelfTrips,
  editRights,
  canGetVouchers,
  canGetInvoices,
  sendDocuments,
  documents,
  approveExpenseReports,
  viewExpenseReports,
  createExpenseReports,
  createERAfterApprove,
  createERUnlimited,
  viewMiceEvents,
}) => {
  const availableRights = [];

  availableRights.push({
    label: RIGHTSTEXT.SEARCHBESTTRIP,
    id: SHORTFIELDRIGHTS.SEARCHBESTTRIP,
  });

  if (buyTripPersonal || buyTripAccount) {
    const mainText = buyTripAccount ? RIGHTSTEXT.BUYTRIPACCOUNT : RIGHTSTEXT.BUYTRIPPERSONAL;

    let additionalText = '';

    if (buyUnlimited) {
      additionalText = RIGHTSTEXT.BUYUNLIMITED;
    } else if (buyAfterApprove) {
      additionalText = RIGHTSTEXT.BUYAFTERAPPROVE;
    } else if (buyTravelPolicy) {
      additionalText = RIGHTSTEXT.BUYTRAVELPOLICY;
    } else if (buyApprovalScheme) {
      additionalText = RIGHTSTEXT.BUY_APPROVAL_SCHEME;
    }

    availableRights.push({
      label: mainText + additionalText,
      id: SHORTFIELDRIGHTS.BUYTRIP,
    });
  }

  if (createExpenseReports) {
    availableRights.push({
      label: RIGHTSTEXT.CREATEEXPENCEREPORTS + selectAdditionalText(createERAfterApprove, createERUnlimited, buyTripAccount),
      id: FIELDRIGHTS.CREATEEXPENCEREPORTS,
    });
  }

  if (viewTrips) {
    availableRights.push({
      label: RIGHTSTEXT.VIEWTRIPS,
      id: FIELDRIGHTS.VIEWTRIPS,
    });
  } else if (viewSelfTrips) {
    availableRights.splice(1, 0, {
      label: RIGHTSTEXT.VIEWSELFTRIPS,
      id: SHORTFIELDRIGHTS.VIEWSELFTRIPS,
    });
  }

  if (finance) {
    availableRights.push({
      label: RIGHTSTEXT.FINANCE,
      id: FIELDRIGHTS.FINANCE,
    });
  }

  if (approve) {
    availableRights.push({
      label: RIGHTSTEXT.APPROVE,
      id: FIELDRIGHTS.APPROVE,
    });
  }

  if (editEmployee) {
    availableRights.push({
      label: RIGHTSTEXT.EDITEMPLOYEE,
      id: FIELDRIGHTS.EDITEMPLOYEE,
    });
  } else if (editSelf) {
    availableRights.push({
      label: RIGHTSTEXT.EDITSELF,
      id: SHORTFIELDRIGHTS.EDITSELF,
    });
  }

  if (approveExpenseReports) {
    availableRights.push({
      label: RIGHTSTEXT.APPROVEEXPENSEREPORTS,
      id: SHORTFIELDRIGHTS.APPROVEEXPENSEREPORTS,
    });
  }

  if (editTravelPolicies) {
    availableRights.push({
      label: RIGHTSTEXT.EDITTRAVELPOLICIES,
      id: FIELDRIGHTS.EDITTRAVELPOLICIES,
    });
  }

  if (viewExpenseReports) {
    availableRights.push({
      label: RIGHTSTEXT.VIEWEXPENSEREPORTS,
      id: FIELDRIGHTS.VIEWEXPENSEREPORTS,
    });
  }

  if (editRights) {
    availableRights.push({
      label: RIGHTSTEXT.EDITRIGHTS,
      id: FIELDRIGHTS.EDITRIGHTS,
    });
  }

  if (viewMiceEvents) {
    availableRights.push({
      label: RIGHTSTEXT.MICE_EVENT,
      id: FIELDRIGHTS.MICE_EVENT,
    });
  }

  if (canGetVouchers || canGetInvoices) {
    const additionalText = documents === DOCUMENTS.PersonalTrips ? RIGHTSTEXT.PERSONALTRIPS : RIGHTSTEXT.ACCOUNTTRIPS;

    availableRights.push({
      label: checkDocuments(canGetVouchers, canGetInvoices) + additionalText,
      id: SHORTFIELDRIGHTS.DOCUMENTS,
    });
  }

  if (sendDocuments) {
    availableRights.push({
      label: RIGHTSTEXT.SENDDOCUMENTS,
      id: FIELDRIGHTS.SENDDOCUMENTS,
    });
  }

  // if (buyInsurance) {
  //   availableRights.push({
  //     label: RIGHTSTEXT.BUY_INSURANCE,
  //     id: FIELDRIGHTS.BUY_INSURANCE,
  //   });
  // }
  // Скрытый релиз страховок

  return availableRights;
};

export const convertUnavailableRightsToText = ({
  buyTripPersonal,
  buyTripAccount,
  // buyInsurance,
  approve,
  finance,
  editSelf,
  editEmployee,
  editTravelPolicies,
  viewTrips,
  viewSelfTrips,
  viewExpenseReports,
  editRights,
  canGetVouchers,
  canGetInvoices,
  sendDocuments,
  documents,
  approveExpenseReports,
  createExpenseReports,
  viewMiceEvents,
}) => {
  const unavailableRights = [];

  if (!buyTripAccount) {
    const text = !buyTripPersonal ? RIGHTSTEXT.NOTBUYTRIP : RIGHTSTEXT.NOTBUYTRIPACCOUNT;

    unavailableRights.push({
      label: text,
      id: SHORTFIELDRIGHTS.NOTBUYTRIP,
    });
  }

  if (!createExpenseReports) {
    unavailableRights.push({
      label: RIGHTSTEXT.CREATEEXPENCEREPORTS,
      id: FIELDRIGHTS.CREATEEXPENCEREPORTS,
    });
  }

  if (!viewTrips && !viewSelfTrips) {
    unavailableRights.push({
      label: RIGHTSTEXT.NOTVIEWTRIPS,
      id: FIELDRIGHTS.VIEWTRIPS,
    });
  } else if (!viewTrips) {
    unavailableRights.push({
      label: RIGHTSTEXT.VIEWTRIPS,
      id: FIELDRIGHTS.VIEWTRIPS,
    });
  }

  if (!finance) {
    unavailableRights.push({
      label: RIGHTSTEXT.FINANCE,
      id: FIELDRIGHTS.FINANCE,
    });
  }

  if (!approve) {
    unavailableRights.push({
      label: RIGHTSTEXT.APPROVE,
      id: FIELDRIGHTS.APPROVE,
    });
  }

  if (!editEmployee && !editSelf) {
    unavailableRights.push({
      label: RIGHTSTEXT.NOTEDITSELF,
      id: SHORTFIELDRIGHTS.EDITSELF,
    });
  } else if (!editEmployee) {
    unavailableRights.push({
      label: RIGHTSTEXT.NOTEDITEMPLOYEE,
      id: FIELDRIGHTS.EDITEMPLOYEE,
    });
  }

  if (!approveExpenseReports) {
    unavailableRights.push({
      label: RIGHTSTEXT.APPROVEEXPENSEREPORTS,
      id: SHORTFIELDRIGHTS.APPROVEEXPENSEREPORTS,
    });
  }

  if (!editTravelPolicies) {
    unavailableRights.push({
      label: RIGHTSTEXT.EDITTRAVELPOLICIES,
      id: FIELDRIGHTS.EDITTRAVELPOLICIES,
    });
  }

  if (!viewExpenseReports) {
    unavailableRights.push({
      label: RIGHTSTEXT.VIEWEXPENSEREPORTS,
      id: FIELDRIGHTS.VIEWEXPENSEREPORTS,
    });
  }

  if (!editRights) {
    unavailableRights.push({
      label: RIGHTSTEXT.EDITRIGHTS,
      id: FIELDRIGHTS.EDITRIGHTS,
    });
  }

  if (!viewMiceEvents) {
    unavailableRights.push({
      label: RIGHTSTEXT.MICE_EVENT,
      id: FIELDRIGHTS.MICE_EVENT,
    });
  }

  if (documents === DOCUMENTS.PersonalTrips && canGetVouchers && canGetInvoices) {
    unavailableRights.push({
      label: checkDocuments(canGetVouchers, canGetInvoices) + RIGHTSTEXT.NOPERSONALTRIPS,
      id: SHORTFIELDRIGHTS.DOCUMENTS,
    });
  } else {
    const canGetDocuments = checkDocuments(!canGetVouchers, !canGetInvoices);

    if (canGetDocuments.length) {
      unavailableRights.push({
        label: checkDocuments(!canGetVouchers, !canGetInvoices) + RIGHTSTEXT.ACCOUNTTRIPS,
        id: SHORTFIELDRIGHTS.DOCUMENTS,
      });
    }
  }

  if (!sendDocuments) {
    unavailableRights.push({
      label: RIGHTSTEXT.SENDDOCUMENTS,
      id: FIELDRIGHTS.SENDDOCUMENTS,
    });
  }

  // if (!buyInsurance) {
  //   unavailableRights.push({
  //     label: RIGHTSTEXT.BUY_INSURANCE,
  //     id: FIELDRIGHTS.BUY_INSURANCE,
  //   });
  // }
  // Скрытый релиз страховок

  return unavailableRights;
};
