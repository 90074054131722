import { SERVICETYPE, SERVICE_POLITICS_TYPE } from '../constants/serviceType';
import { TRAVEL_POLICY_CURRENCIES_LABELS, TRAVEL_POLICY_CURRENCIES } from '../constants/travelPolicy';

const NOT_GREATER_PRICE_TYPE_TEXT = {
  0: 'рублей',
  1: '%',
};

const LABELS = {
  ECONOMY: '"Эконом"',
  COMFORT: '"Комфорт"',
  COMFORNT_PLUS: '"Комфорт+"',
  BUSINESS_EU: '"Business"',
  BUSINESS_RU: '"Бизнес"',
  MINIVAN: '"Минивен"',
  MINIVAN_VIP: '"Минивен VIP"',
  STANDART: '"Стандарт"',
  MINIBUS: 'Микроавтобус',
  MINIBUS_18: '"Микроавтобус, 18 мест"',
  EXECUTIVE: '"Представительский"',
  TOLL_ROAD_STANDART: 'Стандарт (по платной дороге)',
  TOLL_ROAD_COMFORT: 'Комфорт (по платной дороге)',
  TOLL_ROAD_BUSINESS: 'Бизнес (по платной дороге)',
  BUSINESS_LITE: 'Бизнес лайт',
};

const LABELS_TP = {
  WITH_EARLY_IN_LATE_OUT: 'с учетом раннего заезда или позднего выезда',
  WITHOUT_EARLY_IN_LATE_OUT: 'без учета раннего заезда и позднего выезда',
};

const TariffTaxi = (Value) => {
  switch (Value) {
    case 1:
      return LABELS.ECONOMY;
    case 2:
      return LABELS.COMFORT;
    case 3:
      return LABELS.COMFORNT_PLUS;
    case 4:
      return LABELS.BUSINESS_EU;
    case 5:
      return LABELS.MINIVAN;

    default:
      return '';
  }
};

const TariffTransfer = (Value) => {
  switch (Value) {
    case 1:
      return LABELS.STANDART;
    case 2:
      return LABELS.TOLL_ROAD_STANDART;
    case 3:
      return LABELS.COMFORT;
    case 4:
      return LABELS.TOLL_ROAD_COMFORT;
    case 5:
      return LABELS.BUSINESS_LITE;
    case 6:
      return LABELS.BUSINESS_RU;
    case 7:
      return LABELS.TOLL_ROAD_BUSINESS;
    case 8:
      return LABELS.MINIVAN;
    case 9:
      return LABELS.MINIVAN_VIP;
    case 10:
      return LABELS.MINIBUS;
    case 11:
      return LABELS.MINIBUS_18;
    case 12:
      return LABELS.EXECUTIVE;

    default:
      return '';
  }
};

const preparedText = {
  DayOff: (name, value, serviceType) => {
    if (!value) {
      return null;
    }

    if (serviceType === SERVICETYPE.TRAIN) {
      return 'только на поезда, проходящие в будние дни';
    }

    if (serviceType === SERVICETYPE.AIR) {
      return 'только с вылетом и прилетом в будние дни';
    }

    if (serviceType === SERVICETYPE.TRANSFER) {
      return 'только на поездки, проходящие в будние дни';
    }

    return 'только в будние дни';
  },
  Directions: (name, value, serviceType) => {
    const { Routes, Type, IsEnabled } = value;
    if (!IsEnabled) {
      return null;
    }
    if (Type === 1) {
      return 'только по России';
    }
    if (serviceType === SERVICETYPE.HOTEL) {
      const prefix = Routes.length > 1 ? 'городам' : 'городу';
      const preparedRoutes = Routes.map(({ To: { Name } }) => Name).join(', ');
      return `по ${prefix} ${preparedRoutes}`;
    }

    const preparedRoutes = Routes.map(({ From, To }) => `${From.Name} \u21C4 ${To.Name}`);
    return `только по направлениям: ${preparedRoutes.join(', ')}`;
  },
  MaxPrice: (name, value, serviceType) => {
    const { Currency, Value, IsEnabled, ValueCurrency = null, IsEclcNotIncluded = null } = value;
    if (!IsEnabled) {
      return null;
    }

    const currency = TRAVEL_POLICY_CURRENCIES_LABELS[Currency || TRAVEL_POLICY_CURRENCIES.RUB];
    const toCurrency = TRAVEL_POLICY_CURRENCIES_LABELS[TRAVEL_POLICY_CURRENCIES.RUB];

    const textCurrency = Currency !== TRAVEL_POLICY_CURRENCIES.RUB && ValueCurrency ?
      `(${ValueCurrency} ${toCurrency} по текущему курсу ЦБ)`
      : '';

    if (serviceType === SERVICETYPE.HOTEL) {
      const earlyInLateOut = IsEclcNotIncluded
        ? LABELS_TP.WITHOUT_EARLY_IN_LATE_OUT
        : LABELS_TP.WITH_EARLY_IN_LATE_OUT;

      return `не дороже ${Value} ${currency} за ночь ${textCurrency} \n ${earlyInLateOut}`;
    }

    if (serviceType === SERVICETYPE.TRAIN) {
      return `не дороже ${Value} ${currency} в один конец ${textCurrency}`;
    }

    if (serviceType === SERVICETYPE.TRANSFER) {
      return `не дороже ${Value} ${currency}`;
    }

    const textCurrencyBackAgain = Currency !== TRAVEL_POLICY_CURRENCIES.RUB && ValueCurrency ?
      `(${ValueCurrency} ${toCurrency} по текущему курсу ЦБ, ${ValueCurrency * 2} ${toCurrency} туда-обратно)`
      : `(${Value * 2} ${currency} туда-обратно)`;

    return `не дороже ${Value} ${currency} в один конец ${textCurrencyBackAgain}`;
  },
  NotGreaterPrice: (name, value, serviceType) => {
    if (!value) {
      return null;
    }

    const { Value, ValueType, IsEnabled } = value;
    if (!IsEnabled) {
      return null;
    }
    if (serviceType === SERVICETYPE.HOTEL) {
      return `дороже самых дешевых номеров не более, чем на ${Value} ${NOT_GREATER_PRICE_TYPE_TEXT[ValueType]}`;
    }
    return `дороже самых дешевых билетов не более, чем на ${Value} ${NOT_GREATER_PRICE_TYPE_TEXT[ValueType]}`;
  },
  Time: (name, value) => {
    const { Days, IsEnabled } = value;
    if (!IsEnabled) {
      return null;
    }
    return `не позднее, чем за ${Days} дней`;
  },
  Tariff: (name, value) => {
    const { IncludedBaggage, Refundable, Vat } = value;
    const texts = [];
    if (Refundable) {
      texts.push('только по возвратным тарифам');
    }
    if (Vat) {
      texts.push('только с НДС');
    }
    if (IncludedBaggage) {
      texts.push('только с багажом');
    }
    return texts.join(', ');
  },
  TariffScale: (value, serviceType) => {
    const { Value } = value;

    switch (serviceType) {
      case SERVICETYPE.TAXI:

        return TariffTaxi(Value);

      case SERVICETYPE.TRANSFER:

        return TariffTransfer(Value);

      default:
        return '';
    }
  },
  Class: (name, value, serviceType) => {
    const { Value, IsEnabled } = value;
    if (!IsEnabled) {
      return null;
    }
    switch (serviceType) {
      case SERVICETYPE.AIR:
        if (Value === 0) {
          return 'только экономкласс';
        }
        return 'эконом- или бизнес-класс';

      case SERVICETYPE.TRAIN:
        if (Value === 0) return 'только купе';
        if (Value === 1) return 'купе и СВ';

        return 'только плацкарт';

      case SERVICETYPE.TAXI:
        return `только  ${preparedText.TariffScale(value, serviceType)} или более дешевый`;

      case SERVICETYPE.TRANSFER:

        return `только ${preparedText.TariffScale(value, serviceType)} или более дешевый`;

      default:
        return '';
    }
  },
  SapsanClass: (name, value) => {
    const { Value, IsEnabled } = value;
    if (!IsEnabled) {
      return null;
    }
    if (Value === 0) {
      return 'только экономкласс';
    }
    return 'эконом- или бизнес-класс';
  },
  prohibitBook: (name, value) => {
    if (value) {
      return 'запретить покупку такси';
    }
    return '';
  },
  AllowBook: (name, value) => {
    if (value) {
      return 'разрешена покупка vip-залов';
    }

    return '';
  },
};

const getText = (name, value, serviceType) => {
  const prepareTextByName = preparedText[name];
  return prepareTextByName ? prepareTextByName(name, value, serviceType) : null;
};

const prepareMessages = (policy, serviceType) => {
  const messages = [];
  Object.keys(policy).forEach((k) => {
    const message = getText(k, policy[k], serviceType);

    if (message) {
      if (serviceType === SERVICETYPE.AIR && k === 'Tariff') {
        message.split(', ').forEach(m => messages.push(m));
      } else {
        messages.push(message);
      }
    }
  });

  return messages;
};

const getTextExtptionName = ({ Routes, Hours, Type }, serviceType) => {
  if (serviceType === SERVICETYPE.HOTEL) {
    const countryText = Routes.length > 1 ? 'странам' : 'странe';
    const cityText = Routes.length > 1 ? 'городам' : 'городу';
    const prefix = Type === 1 ? cityText : countryText;
    const preparedRoutes = Routes.map(({ To: { Name } }) => Name).join(', ');
    return `по ${prefix} ${preparedRoutes}`;
  }

  switch (Type) {
    case 0:
      return `для перелетов дольше ${Hours} часов:`;
    case 1:
    case 2: {
      const prefix = Type === 1 ? 'городам' : 'странам';
      return `для направлений по ${prefix}: ${Routes.map(({ From, To }) => `${From.Name} \u21C4 ${To.Name}`)}`;
    }
    default:
      return `по направлению: ${Routes.map(({ From, To }) => `${From.Name} \u21C4 ${To.Name}`)}`;
  }
};

const getTextExtptions = (exception, serviceType) => {
  if (!exception.length) {
    return [];
  }

  return exception.map((execptionPolicy) => {
    const name = getTextExtptionName(execptionPolicy, serviceType);
    const rules = prepareMessages(execptionPolicy, serviceType);

    return { name, rules };
  });
};

export const buildTPMessages = (rule, type) => {
  let exception;

  if (!rule[SERVICE_POLITICS_TYPE[type]].Apply) {
    return { isEmpty: true };
  }

  if (type === SERVICETYPE.TAXI || type === SERVICETYPE.TRANSFER || type === SERVICETYPE.VIP_HALL) {
    exception = [];
  } else {
    exception = getTextExtptions(rule[SERVICE_POLITICS_TYPE[type]].Exceptions, type);
  }

  const rules = prepareMessages(rule[SERVICE_POLITICS_TYPE[type]], type);
  const isEmpty = !rules.length && !exception.length;

  return { rules, exception, isEmpty };
};
